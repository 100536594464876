.parallax-container {
  height: 400px;
}
@media only screen and (max-width : 992px) {
  .parallax-container {
    height: 150px;
  }
  .parallax img {
    max-height: 200%;
  }
  .parallax-container img.linusTorvalds {
    max-width: 125%;
  }
}
@media only screen and (min-width: 993px) {
  .parallax-container .parallax img {
    max-height: 170%;
  }
}