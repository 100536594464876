.Presentation {
  align-items: center;
  text-shadow: 5px 5px rgba(0,0,0,0.1);
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.Presentation .other {
  font-size: 0.6em;
  visibility: hidden;
}
.Presentation #nameContainer {
  display: flex;
  flex-direction: row;

  font-size: 15em;
  font-weight: bold;

  animation-name: nameContainerAppear;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  opacity: 0;
  cursor: pointer;
}
@media only screen and (max-width : 992px) {
  .Presentation #nameContainer {
    animation-name: none;
    opacity: 1;
  }
}
@keyframes nameContainerAppear {
  0% {
      opacity: 0;
      transform: translateY(-3000px);
  }
  60% {

      transform: translateY(25px);
  }
  75% {
      transform: translateY(-10px);
  }
  90% {
      transform: translateY(5px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

.Presentation #name {
  width: .5em;
  overflow-x: hidden;
}
.Presentation #name.showName:hover {
  animation-name: nameMove;
  animation-duration: .7s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.Presentation #name.showName:hover .other,
.Presentation #name.showAlias .other,
.Presentation #name.resetAlias .other,
.Presentation #name.showSocial .other,
.Presentation #name.resetSocial .other {
  visibility: initial;
}
.Presentation #name.showAlias {
  animation-name: nameAliasMove;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.Presentation #name.resetAlias {
  animation-name: nameAliasResetMove;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.Presentation #name.showSocial {
  animation-name: nameSocialMove;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.Presentation #name.resetSocial {
  animation-name: nameSocialResetMove;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

@keyframes nameMove {
  0% {
      width: .5em;
  }
  100% {
      width: 1.6em;
  }
}
@keyframes nameAliasMove {
  0% {
      transform: translateX(0);
      width: .5em;
  }
  50% {
      transform: translateX(0);
      width: .9em;
  }
  80% {
      transform: translateX(0);
      width: .9em;
  }
  100% {
      transform: translateX(2.7em);
      width: .9em;
  }
}
@keyframes nameAliasResetMove {
  0% {
      transform: translateX(2.7em);
      width: .9em;
  }
  50% {
      transform: translateX(0);
      width: .9em;
  }
  80% {
      transform: translateX(0);
      width: .9em;
  }
  100% {
      transform: translateX(0);
      width: .5em;
  }
}
@keyframes nameSocialMove {
  0% {
      transform: translateX(0);
      width: .5em;
  }
  100% {
      transform: translateX(0);
      width: 0em;
  }
}
@keyframes nameSocialResetMove {
  0% {
      transform: translateX(0);
      width: 0em;
  }
  100% {
      transform: translateX(0);
      width: .5em;
  }
}
.Presentation #firstLastName {
  width: 0.6em;
  overflow-x: hidden;
}
.Presentation #firstLastName.showName:hover {
  animation-name: firstLastNameMove;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.Presentation #firstLastName.showName:hover .other,
.Presentation #firstLastName.showAlias .other,
.Presentation #firstLastName.resetAlias .other,
.Presentation #firstLastName.showSocial .other,
.Presentation #firstLastName.resetSocial .other{
  visibility: initial;
}
.Presentation #firstLastName.showAlias {
  animation-name: firstLastNameAliasMove;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.Presentation #firstLastName.resetAlias {
  animation-name: firstLastNameAliasResetMove;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.Presentation #firstLastName.showSocial {
  animation-name: firstLastNameSocialMove;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.Presentation #firstLastName.resetSocial {
  animation-name: firstLastNameSocialResetMove;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes firstLastNameMove {
  0% {
      width: 0.6em;
  }
  100% {
      width: 3.1em;
  }
}
@keyframes firstLastNameAliasMove {
  0% {
      transform: translateX(0);
      width: 0.6em;
  }
  50% {
      transform: translateX(0);
      width: 1.5em;
  }
  80% {
      transform: translateY(1em);
      width: 1.5em;
  }
  100% {
      transform: translateX(-0.85em);
      width: 1.5em;
  }
}
@keyframes firstLastNameAliasResetMove {
  0% {
      transform: translateX(-1em);
      width: 1.5em;
  }
  50% {
      transform: translateY(1em);
      width: 1.5em;
  }
  80% {
      transform: translateX(0);
      width: 1.5em;
  }
  100% {
      transform: translateX(0);
      width: 0.6em;
  }
}
@keyframes firstLastNameSocialMove {
  0% {
      width: .5em;
  }
  100% {
      width: 2.19em;
  }
}
@keyframes firstLastNameSocialResetMove {
  0% {
    width: 2.19em;
  }
  100% {
    width: .5em;
  }
}
.Presentation #secondLastName {
  width: 0.65em;
  overflow-x: hidden;
}
.Presentation #secondLastName.showName:hover {
  animation-name: secondLastNameMove;
  animation-duration: 1.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.Presentation #secondLastName.showName:hover .other,
.Presentation #secondLastName.showAlias .other,
.Presentation #secondLastName.resetAlias .other,
.Presentation #secondLastName.showSocial .other,
.Presentation #secondLastName.resetSocial .other {
  visibility: initial;
}
.Presentation #secondLastName.showAlias {
  animation-name: secondLastNameAliasMove;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
.Presentation #secondLastName.resetAlias {
  animation-name: secondLastNameAliasResetMove;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
.Presentation #secondLastName.showSocial {
  animation-name: secondLastNameSocialMove;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
.Presentation #secondLastName.resetSocial {
  animation-name: secondLastNameSocialResetMove;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes secondLastNameMove {
  0% {
      width: 0.7em;
  }
  100% {
      width: 3.1em;
  }
}
@keyframes secondLastNameAliasMove {
  0% {
      transform: translateX(0);
      width: 0.7em;
  }
  50% {
      transform: translateX(0);
      width: 1.3em;
  }
  80% {
      transform: translateY(-1em);
      width: 1.5em;
  }
  100% {
      transform: translateX(-0.9em);
      width: 1.2em;
  }
}
@keyframes secondLastNameAliasResetMove {
  0% {
      transform: translateX(-1em);
      width: 1.3em;
  }
  50% {
      transform: translateY(-1em);
      width: 1.5em;
  }
  80% {
      transform: translateX(0);
      width: 1.3em;
  }
  100% {
      transform: translateX(0);
      width: 0.7em;
  }
}
@keyframes secondLastNameSocialMove {
  0% {
      transform: translateX(0);
      width: .5em;
  }
  100% {
      transform: translateX(0);
      width: 0em;
  }
}
@keyframes secondLastNameSocialResetMove {
  0% {
    transform: translateX(0);
    width: 0em;
  }
  100% {
    transform: translateX(0);
    width: .5em;
  }
}
.Presentation #socialContainer {
  cursor: pointer;
  font-size: 3em;
  text-decoration: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Presentation #socialContainer i {
  margin: .5em;
}
.Presentation #socialContainer i:hover {
  color: #DB5461;
}
.Presentation #socialContainer a {
  color: #8EA8C3;
}
.Presentation #switch {
  font-size: 3em;
  color: #8EA8C3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}
.Presentation #switch i {
  cursor: pointer;
  margin-left: 15px;
}
.Presentation #switch i.active {
  color: #DB5461;
}
.Presentation #motto {
  text-shadow: none;
}