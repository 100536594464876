.Sidebar {
  background-color: #3d5467;
}
.Sidebar nav {
  background-color: #da5461;
}
.Sidebar nav .brand-logo {
  margin-left: 1%;
  color: #3d5467;
}
.Sidebar .sidenav {
  background-color: #da5461;
  width: auto;
}
.Sidebar .sidenav li>a {
  color: #3d5467;
  font-weight: bold;
  font-size: 1.5em;
  text-align: right;
}