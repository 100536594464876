@import url(https://cdn.jsdelivr.net/gh/tonsky/FiraCode@1.207/distr/fira_code.css);
.Sidebar {
  background-color: #3d5467;
}
.Sidebar nav {
  background-color: #da5461;
}
.Sidebar nav .brand-logo {
  margin-left: 1%;
  color: #3d5467;
}
.Sidebar .sidenav {
  background-color: #da5461;
  width: auto;
}
.Sidebar .sidenav li>a {
  color: #3d5467;
  font-weight: bold;
  font-size: 1.5em;
  text-align: right;
}
.Presentation {
  align-items: center;
  text-shadow: 5px 5px rgba(0,0,0,0.1);
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.Presentation .other {
  font-size: 0.6em;
  visibility: hidden;
}
.Presentation #nameContainer {
  display: flex;
  flex-direction: row;

  font-size: 15em;
  font-weight: bold;

  -webkit-animation-name: nameContainerAppear;

          animation-name: nameContainerAppear;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;

  opacity: 0;
  cursor: pointer;
}
@media only screen and (max-width : 992px) {
  .Presentation #nameContainer {
    -webkit-animation-name: none;
            animation-name: none;
    opacity: 1;
  }
}
@-webkit-keyframes nameContainerAppear {
  0% {
      opacity: 0;
      transform: translateY(-3000px);
  }
  60% {

      transform: translateY(25px);
  }
  75% {
      transform: translateY(-10px);
  }
  90% {
      transform: translateY(5px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}
@keyframes nameContainerAppear {
  0% {
      opacity: 0;
      transform: translateY(-3000px);
  }
  60% {

      transform: translateY(25px);
  }
  75% {
      transform: translateY(-10px);
  }
  90% {
      transform: translateY(5px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

.Presentation #name {
  width: .5em;
  overflow-x: hidden;
}
.Presentation #name.showName:hover {
  -webkit-animation-name: nameMove;
          animation-name: nameMove;
  -webkit-animation-duration: .7s;
          animation-duration: .7s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.Presentation #name.showName:hover .other,
.Presentation #name.showAlias .other,
.Presentation #name.resetAlias .other,
.Presentation #name.showSocial .other,
.Presentation #name.resetSocial .other {
  visibility: visible;
  visibility: initial;
}
.Presentation #name.showAlias {
  -webkit-animation-name: nameAliasMove;
          animation-name: nameAliasMove;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.Presentation #name.resetAlias {
  -webkit-animation-name: nameAliasResetMove;
          animation-name: nameAliasResetMove;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.Presentation #name.showSocial {
  -webkit-animation-name: nameSocialMove;
          animation-name: nameSocialMove;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.Presentation #name.resetSocial {
  -webkit-animation-name: nameSocialResetMove;
          animation-name: nameSocialResetMove;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}

@-webkit-keyframes nameMove {
  0% {
      width: .5em;
  }
  100% {
      width: 1.6em;
  }
}

@keyframes nameMove {
  0% {
      width: .5em;
  }
  100% {
      width: 1.6em;
  }
}
@-webkit-keyframes nameAliasMove {
  0% {
      transform: translateX(0);
      width: .5em;
  }
  50% {
      transform: translateX(0);
      width: .9em;
  }
  80% {
      transform: translateX(0);
      width: .9em;
  }
  100% {
      transform: translateX(2.7em);
      width: .9em;
  }
}
@keyframes nameAliasMove {
  0% {
      transform: translateX(0);
      width: .5em;
  }
  50% {
      transform: translateX(0);
      width: .9em;
  }
  80% {
      transform: translateX(0);
      width: .9em;
  }
  100% {
      transform: translateX(2.7em);
      width: .9em;
  }
}
@-webkit-keyframes nameAliasResetMove {
  0% {
      transform: translateX(2.7em);
      width: .9em;
  }
  50% {
      transform: translateX(0);
      width: .9em;
  }
  80% {
      transform: translateX(0);
      width: .9em;
  }
  100% {
      transform: translateX(0);
      width: .5em;
  }
}
@keyframes nameAliasResetMove {
  0% {
      transform: translateX(2.7em);
      width: .9em;
  }
  50% {
      transform: translateX(0);
      width: .9em;
  }
  80% {
      transform: translateX(0);
      width: .9em;
  }
  100% {
      transform: translateX(0);
      width: .5em;
  }
}
@-webkit-keyframes nameSocialMove {
  0% {
      transform: translateX(0);
      width: .5em;
  }
  100% {
      transform: translateX(0);
      width: 0em;
  }
}
@keyframes nameSocialMove {
  0% {
      transform: translateX(0);
      width: .5em;
  }
  100% {
      transform: translateX(0);
      width: 0em;
  }
}
@-webkit-keyframes nameSocialResetMove {
  0% {
      transform: translateX(0);
      width: 0em;
  }
  100% {
      transform: translateX(0);
      width: .5em;
  }
}
@keyframes nameSocialResetMove {
  0% {
      transform: translateX(0);
      width: 0em;
  }
  100% {
      transform: translateX(0);
      width: .5em;
  }
}
.Presentation #firstLastName {
  width: 0.6em;
  overflow-x: hidden;
}
.Presentation #firstLastName.showName:hover {
  -webkit-animation-name: firstLastNameMove;
          animation-name: firstLastNameMove;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.Presentation #firstLastName.showName:hover .other,
.Presentation #firstLastName.showAlias .other,
.Presentation #firstLastName.resetAlias .other,
.Presentation #firstLastName.showSocial .other,
.Presentation #firstLastName.resetSocial .other{
  visibility: visible;
  visibility: initial;
}
.Presentation #firstLastName.showAlias {
  -webkit-animation-name: firstLastNameAliasMove;
          animation-name: firstLastNameAliasMove;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.Presentation #firstLastName.resetAlias {
  -webkit-animation-name: firstLastNameAliasResetMove;
          animation-name: firstLastNameAliasResetMove;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.Presentation #firstLastName.showSocial {
  -webkit-animation-name: firstLastNameSocialMove;
          animation-name: firstLastNameSocialMove;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.Presentation #firstLastName.resetSocial {
  -webkit-animation-name: firstLastNameSocialResetMove;
          animation-name: firstLastNameSocialResetMove;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
@-webkit-keyframes firstLastNameMove {
  0% {
      width: 0.6em;
  }
  100% {
      width: 3.1em;
  }
}
@keyframes firstLastNameMove {
  0% {
      width: 0.6em;
  }
  100% {
      width: 3.1em;
  }
}
@-webkit-keyframes firstLastNameAliasMove {
  0% {
      transform: translateX(0);
      width: 0.6em;
  }
  50% {
      transform: translateX(0);
      width: 1.5em;
  }
  80% {
      transform: translateY(1em);
      width: 1.5em;
  }
  100% {
      transform: translateX(-0.85em);
      width: 1.5em;
  }
}
@keyframes firstLastNameAliasMove {
  0% {
      transform: translateX(0);
      width: 0.6em;
  }
  50% {
      transform: translateX(0);
      width: 1.5em;
  }
  80% {
      transform: translateY(1em);
      width: 1.5em;
  }
  100% {
      transform: translateX(-0.85em);
      width: 1.5em;
  }
}
@-webkit-keyframes firstLastNameAliasResetMove {
  0% {
      transform: translateX(-1em);
      width: 1.5em;
  }
  50% {
      transform: translateY(1em);
      width: 1.5em;
  }
  80% {
      transform: translateX(0);
      width: 1.5em;
  }
  100% {
      transform: translateX(0);
      width: 0.6em;
  }
}
@keyframes firstLastNameAliasResetMove {
  0% {
      transform: translateX(-1em);
      width: 1.5em;
  }
  50% {
      transform: translateY(1em);
      width: 1.5em;
  }
  80% {
      transform: translateX(0);
      width: 1.5em;
  }
  100% {
      transform: translateX(0);
      width: 0.6em;
  }
}
@-webkit-keyframes firstLastNameSocialMove {
  0% {
      width: .5em;
  }
  100% {
      width: 2.19em;
  }
}
@keyframes firstLastNameSocialMove {
  0% {
      width: .5em;
  }
  100% {
      width: 2.19em;
  }
}
@-webkit-keyframes firstLastNameSocialResetMove {
  0% {
    width: 2.19em;
  }
  100% {
    width: .5em;
  }
}
@keyframes firstLastNameSocialResetMove {
  0% {
    width: 2.19em;
  }
  100% {
    width: .5em;
  }
}
.Presentation #secondLastName {
  width: 0.65em;
  overflow-x: hidden;
}
.Presentation #secondLastName.showName:hover {
  -webkit-animation-name: secondLastNameMove;
          animation-name: secondLastNameMove;
  -webkit-animation-duration: 1.3s;
          animation-duration: 1.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.Presentation #secondLastName.showName:hover .other,
.Presentation #secondLastName.showAlias .other,
.Presentation #secondLastName.resetAlias .other,
.Presentation #secondLastName.showSocial .other,
.Presentation #secondLastName.resetSocial .other {
  visibility: visible;
  visibility: initial;
}
.Presentation #secondLastName.showAlias {
  -webkit-animation-name: secondLastNameAliasMove;
          animation-name: secondLastNameAliasMove;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.Presentation #secondLastName.resetAlias {
  -webkit-animation-name: secondLastNameAliasResetMove;
          animation-name: secondLastNameAliasResetMove;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.Presentation #secondLastName.showSocial {
  -webkit-animation-name: secondLastNameSocialMove;
          animation-name: secondLastNameSocialMove;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.Presentation #secondLastName.resetSocial {
  -webkit-animation-name: secondLastNameSocialResetMove;
          animation-name: secondLastNameSocialResetMove;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes secondLastNameMove {
  0% {
      width: 0.7em;
  }
  100% {
      width: 3.1em;
  }
}
@keyframes secondLastNameMove {
  0% {
      width: 0.7em;
  }
  100% {
      width: 3.1em;
  }
}
@-webkit-keyframes secondLastNameAliasMove {
  0% {
      transform: translateX(0);
      width: 0.7em;
  }
  50% {
      transform: translateX(0);
      width: 1.3em;
  }
  80% {
      transform: translateY(-1em);
      width: 1.5em;
  }
  100% {
      transform: translateX(-0.9em);
      width: 1.2em;
  }
}
@keyframes secondLastNameAliasMove {
  0% {
      transform: translateX(0);
      width: 0.7em;
  }
  50% {
      transform: translateX(0);
      width: 1.3em;
  }
  80% {
      transform: translateY(-1em);
      width: 1.5em;
  }
  100% {
      transform: translateX(-0.9em);
      width: 1.2em;
  }
}
@-webkit-keyframes secondLastNameAliasResetMove {
  0% {
      transform: translateX(-1em);
      width: 1.3em;
  }
  50% {
      transform: translateY(-1em);
      width: 1.5em;
  }
  80% {
      transform: translateX(0);
      width: 1.3em;
  }
  100% {
      transform: translateX(0);
      width: 0.7em;
  }
}
@keyframes secondLastNameAliasResetMove {
  0% {
      transform: translateX(-1em);
      width: 1.3em;
  }
  50% {
      transform: translateY(-1em);
      width: 1.5em;
  }
  80% {
      transform: translateX(0);
      width: 1.3em;
  }
  100% {
      transform: translateX(0);
      width: 0.7em;
  }
}
@-webkit-keyframes secondLastNameSocialMove {
  0% {
      transform: translateX(0);
      width: .5em;
  }
  100% {
      transform: translateX(0);
      width: 0em;
  }
}
@keyframes secondLastNameSocialMove {
  0% {
      transform: translateX(0);
      width: .5em;
  }
  100% {
      transform: translateX(0);
      width: 0em;
  }
}
@-webkit-keyframes secondLastNameSocialResetMove {
  0% {
    transform: translateX(0);
    width: 0em;
  }
  100% {
    transform: translateX(0);
    width: .5em;
  }
}
@keyframes secondLastNameSocialResetMove {
  0% {
    transform: translateX(0);
    width: 0em;
  }
  100% {
    transform: translateX(0);
    width: .5em;
  }
}
.Presentation #socialContainer {
  cursor: pointer;
  font-size: 3em;
  text-decoration: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Presentation #socialContainer i {
  margin: .5em;
}
.Presentation #socialContainer i:hover {
  color: #DB5461;
}
.Presentation #socialContainer a {
  color: #8EA8C3;
}
.Presentation #switch {
  font-size: 3em;
  color: #8EA8C3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}
.Presentation #switch i {
  cursor: pointer;
  margin-left: 15px;
}
.Presentation #switch i.active {
  color: #DB5461;
}
.Presentation #motto {
  text-shadow: none;
}
.parallax-container {
  height: 400px;
}
@media only screen and (max-width : 992px) {
  .parallax-container {
    height: 150px;
  }
  .parallax img {
    max-height: 200%;
  }
  .parallax-container img.linusTorvalds {
    max-width: 125%;
  }
}
@media only screen and (min-width: 993px) {
  .parallax-container .parallax img {
    max-height: 170%;
  }
}
#presentation p {
  font-size: 1.4em;
}
#presentation #me {
  margin: .5rem 0 1rem 0;
}

#presentation .shell-wrap {
  margin: .5rem 0 1rem 0;
  box-shadow: 0 0 30px rgba(0,0,0,0.4);
  border-radius: 3px;
}

#presentation .shell-top-bar {
  text-align: center;
  color: #525252;
  padding: 5px 0;
  margin: 0;
  text-shadow: 1px 1px 0 rgba(255,255,255,0.5);
  font-size: 0.85em;
  border: 1px solid #CCCCCC;
  border-bottom: none;

  -webkit-border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-topright: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  background: #f7f7f7; /* Old browsers */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(to bottom,  #f7f7f7 0%,#B8B8B8 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#B8B8B8',GradientType=0 ); /* IE6-9 */
}

#presentation .shell-body {
  margin: 0;
  padding: 5px;
  list-style: none;
  background: #141414;
  color: #45D40C;
  font: 0.8em 'Andale Mono', Consolas, 'Courier New';
  line-height: 1.6em;

  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

#presentation .shell-body li:before {
  content: '$';
  position: absolute;
  left: 0;
  top: 0;
}

#presentation .shell-body li {
  word-wrap: break-word;
  position: relative;
  padding: 0 0 0 15px;
}
.workExperience .job-title,
.workExperience .company-title,
.workExperience .duration-title {
  color: #3d5467;
  font-weight: bold;
  text-align: right;
}
.workExperience .job {
  font-weight: bold;
}
.workExperience .job,
.workExperience .company,
.workExperience .duration {
  text-align: left;
}
.content {
  text-align: justify;
}
.workExperienceImg-container {
  max-width: 100%;
}
.workExperienceImg-container img {
  width: 100%;
  padding: 5%;
}
div.skillContainer {
  width: 100%;
  border: 1px solid #26272b;
  background-color: #ffffff;
  border-radius: 5px;
  /* cursor: pointer; */
  padding: 1vh;
  min-height: 1vh;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

article.skill img {
  height: 100%;
}
div.Study {
  border-radius: 5px;
  background-color: white;
  padding: 1vh;
  font-weight: bold;
}
div.Study .title {
  font-size: 1.5em;
}
div.Study .center {
  text-align: left;
  font-size: 1em;
}
div.Study .dates {
  color: #26272b;
}
#aboutme {
  font-family: 'Fira Code', monospace;
  text-align: justify;
  margin-top: 5vh;
}
#aboutme img {
  border-radius: 1vh;
}
#aboutme .row > .tabs-content,
#aboutme .col > .tabs-content {
  min-height: 100%;
}
.tabs-content.carousel.carousel-slider .carousel-item.active{
  position: relative;
}  
.tabs-content.carousel.carousel-slider{
  height: auto !important;
}
.collection-item .col > img {
  display: flex;
}
.collection-item img {
  border: 1px solid #3d5467;
  justify-content: center;
  align-items: center;
}
#projects {
  flex-direction: column;
}

#projects .card .card-title{
  font-size: 1.4em;
}

#projects ul {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

#projects li.tag {
  border: 1px solid black;
  padding: 2px;
  border-radius: 4px;
  margin-right: 5px;
  color: white;
  background-color: #3d5467;
}
.page-footer {
  background-color: #DB5461;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  color: #DB5461;
  background-color: #3d5467;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

div.section,
section {
  min-height: 100vh;
}
